import { USERROLE } from 'Shared/types';

import { mapUserAPIProjectsListToProjectsList } from '../projects/dataFormatters';
import { UserProject, UserProjectWithRole } from '../projects/types';

import {
  APIUserGroup,
  APIUserGroupDetails,
  UserGroup,
  UserGroupRoleInProject,
  UserGroupWithProjectRole,
} from './types';

export const mapAPIUserGroupToUserGroup = ({ projects, ...rest }: APIUserGroup): UserGroup => ({
  ...rest,
  projects: mapUserAPIProjectsListToProjectsList(projects),
});

function addRolesToProjects(
  projects: UserProject[],
  userGroupRoleInProjects: UserGroupRoleInProject[],
): UserProjectWithRole[] {
  const adminGuids = userGroupRoleInProjects
    .filter((group) => group.groupRole === USERROLE.ADMIN)
    .map(({ projectGUID }) => projectGUID);

  const projectsWithRole = projects.map((project) => ({
    ...project,
    role: adminGuids.includes(project.guid) ? USERROLE.ADMIN : USERROLE.VIEWER,
  }));

  return projectsWithRole;
}

export const mapAPIUserGroupDetailsToUserGroup = ({
  projects,
  userGroupRoleInProjects,
  ...rest
}: APIUserGroupDetails): UserGroupWithProjectRole => ({
  ...rest,
  projects:
    projects && userGroupRoleInProjects
      ? addRolesToProjects(mapUserAPIProjectsListToProjectsList(projects), userGroupRoleInProjects)
      : [],
});

export const mapAPIUserGroupsListToUserGroupsList = (apiList?: APIUserGroup[]): UserGroup[] =>
  apiList?.map(mapAPIUserGroupToUserGroup) ?? [];

export default {};
