import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import './shared/utils/sentry';
import './shared/utils/analytics';

import createStore from 'RioRedux/index';
import SentryErrorBoundry from 'Shared/components/SentryErrorBoundry';
import history from 'Shared/utils/history';

import Layout from './Layout';

import 'GlobalStyles/tailwind.scss';
import 'antd/dist/antd.css';
import 'react-grid-layout/css/styles.css';
import 'react-virtualized/styles.css';
import 'normalize.css';
import 'vis/dist/vis.css';
import 'Shared/style/style.scss';
import './devices/details/style/deviceSsh.scss';
import './shared/style/loading.scss';
import './shared/style/homePage.scss';
import './shared/style/dashboards.scss';
import './shared/style/pageError.scss';
import './shared/style/listPage.scss';
import './shared/style/detailsPage.scss';
import './shared/style/modal.scss';
import './shared/style/tos.scss';
import './additonal_resources/style.scss';
import './configurations/style.scss';
import './devices/style.scss';
import './deployments/style.scss';
import './shared/style/antd-customize.scss';

ReactDOM.render(
  <SentryErrorBoundry>
    <Provider store={createStore()}>
      <ConnectedRouter history={history}>
        <Layout />
      </ConnectedRouter>
    </Provider>
  </SentryErrorBoundry>,
  document.getElementById('app'),
);
