export const CommunityV2Plan = {
  ID: 2,
  CreatedAt: '2024-12-19T09:04:39.756656Z',
  UpdatedAt: '2024-12-19T09:04:39.756656Z',
  DeletedAt: null,
  name: 'CommunityV2',
  planComponents: [
    {
      ID: 13,
      CreatedAt: '2024-12-19T09:04:39.771564Z',
      UpdatedAt: '2024-12-19T09:04:39.771564Z',
      DeletedAt: null,
      name: 'Device Deployment Hours',
      uomName: 'Device-Deployment-Hours',
      PlanId: 2,
      chargeType: 'overage',
      metadata: 'Indicates the hours of deployments consumed by the devices',
      unit: 'Hour',
      isDisplay: false,
      displayPriority: 0,
    },
    {
      ID: 18,
      CreatedAt: '2024-12-19T09:04:39.793242Z',
      UpdatedAt: '2024-12-19T09:04:39.793242Z',
      DeletedAt: null,
      name: 'Features',
      PlanId: 2,
      metadata: '- Access to community packages',
      isDisplay: true,
      displayPriority: 1,
    },
    {
      ID: 10,
      CreatedAt: '2024-12-19T09:04:39.759203Z',
      UpdatedAt: '2024-12-19T09:04:39.759203Z',
      DeletedAt: null,
      name: 'Devices',
      uomName: 'Devices',
      includedUnits: 6,
      PlanId: 2,
      chargeType: 'add-on',
      metadata: 'Indicates the number of devices added to the platform',
      unit: 'Device',
      isDisplay: true,
      planLimits: [
        {
          ID: 6,
          CreatedAt: '2024-12-19T09:04:39.762113Z',
          UpdatedAt: '2024-12-19T09:04:39.762113Z',
          DeletedAt: null,
          name: 'MaxDevices',
          PlanComponentID: 10,
          maxLimit: 15,
        },
      ],
      displayPriority: 2,
    },
    {
      ID: 11,
      CreatedAt: '2024-12-19T09:04:39.76478Z',
      UpdatedAt: '2024-12-19T09:04:39.76478Z',
      DeletedAt: null,
      name: 'Users',
      uomName: 'Users',
      includedUnits: 5,
      PlanId: 2,
      chargeType: 'add-on',
      metadata: 'Indicates the numbers of active users',
      unit: 'User',
      isDisplay: true,
      planLimits: [
        {
          ID: 7,
          CreatedAt: '2024-12-19T09:04:39.767195Z',
          UpdatedAt: '2024-12-19T09:04:39.767195Z',
          DeletedAt: null,
          name: 'MaxUsers',
          PlanComponentID: 11,
          maxLimit: 10,
        },
      ],
      displayPriority: 3,
    },
    {
      ID: 12,
      CreatedAt: '2024-12-19T09:04:39.769375Z',
      UpdatedAt: '2024-12-19T09:04:39.769375Z',
      DeletedAt: null,
      name: 'Cloud Deployment Hours',
      uomName: 'Cloud-Deployment-Hours',
      includedUnits: 100,
      PlanId: 2,
      chargeType: 'overage',
      metadata: 'Indicates the hours of deployments consumed in the cloud',
      unit: 'vCPU-Hour',
      isDisplay: true,
      displayPriority: 4,
    },
    {
      ID: 14,
      CreatedAt: '2024-12-19T09:04:39.776086Z',
      UpdatedAt: '2024-12-19T09:04:39.776086Z',
      DeletedAt: null,
      name: 'Volume Deployment Hours',
      uomName: 'Volume-Deployment-Hours',
      PlanId: 2,
      chargeType: 'overage',
      metadata: 'Indicates the hours consumed by storage volumes deployments',
      unit: 'GiB-Hour',
      isDisplay: true,
      planLimits: [
        {
          ID: 8,
          CreatedAt: '2024-12-19T09:04:39.778323Z',
          UpdatedAt: '2024-12-19T09:04:39.778323Z',
          DeletedAt: null,
          name: 'MaxVolumes',
          PlanComponentID: 14,
          maxLimit: 10,
        },
        {
          ID: 9,
          CreatedAt: '2024-12-19T09:04:39.780783Z',
          UpdatedAt: '2024-12-19T09:04:39.780783Z',
          DeletedAt: null,
          name: 'MaxVolumeSizeGB',
          PlanComponentID: 14,
          maxLimit: 1024,
        },
      ],
      displayPriority: 5,
    },
    {
      ID: 15,
      CreatedAt: '2024-12-19T09:04:39.783114Z',
      UpdatedAt: '2024-12-19T09:04:39.783114Z',
      DeletedAt: null,
      name: 'Static Routes',
      uomName: 'Static-Routes',
      includedUnits: 1,
      PlanId: 2,
      chargeType: 'add-on',
      metadata: 'Indicates the number of static routes added to the platform',
      unit: 'Static-Route',
      isDisplay: true,
      planLimits: [
        {
          ID: 10,
          CreatedAt: '2024-12-19T09:04:39.785683Z',
          UpdatedAt: '2024-12-19T09:04:39.785683Z',
          DeletedAt: null,
          name: 'MaxStaticRoutes',
          PlanComponentID: 15,
          maxLimit: 5,
        },
      ],
      displayPriority: 6,
    },
    {
      ID: 16,
      CreatedAt: '2024-12-19T09:04:39.788055Z',
      UpdatedAt: '2024-12-19T09:04:39.788055Z',
      DeletedAt: null,
      name: 'Support',
      PlanId: 2,
      metadata: 'Community Support',
      isDisplay: true,
      displayPriority: 7,
    },
    {
      ID: 17,
      CreatedAt: '2024-12-19T09:04:39.790659Z',
      UpdatedAt: '2024-12-19T09:04:39.790659Z',
      DeletedAt: null,
      name: 'SLA',
      PlanId: 2,
      metadata: 'None',
      isDisplay: true,
      displayPriority: 8,
    },
  ],
  metadata: 'Academia, research, startups in PoC',
  planType: 'Public',
  currency: 'USD',
  isActive: true,
  displayName: 'Community',
  trialPeriodMonths: 3,
};

export default {};
