import { AxiosError } from 'axios';
import { push } from 'connected-react-router';
import { call, ForkEffect, put, select, takeLatest } from 'redux-saga/effects';

import { getUserDetails } from 'Models/common/service';
import { APICallStatus, EntityState, User, UserRoles } from 'Models/common/types';
import { NotificationPayload } from 'Models/notifications/types';
import {
  getCountryList as getCountryListAPI,
  getOrganizationDetails as getOrganizationDetailsAPI,
  getProvinceList as getProvinceListAPI,
  register as registerAPI,
  submitPaymentInfo,
  toggleUserState as toggleUserStateAPI,
} from 'Models/organization/service';
import {
  OrgAction,
  Organization,
  OrgRegistrationStatus,
  PaymentInfoParams,
  ToggleUserStatePayload,
} from 'Models/organization/types';
import { Project, UserProject } from 'Models/projects/types';
import {
  createUserGroup as createUserGroupAPI,
  deleteUserGroup as deleteUserGroupAPI,
  getUserGroupDetails as getUserGroupDetailsAPI,
  getUserGroupsList,
  leaveUserGroup as leaveUserGroupAPI,
  updateUserGroup as updateUserGroupAPI,
} from 'Models/userGroups/service';
import {
  CreateUserGroupData,
  CreateUserGroupReq,
  UpdateUserGroupData,
  UserGroup,
} from 'Models/userGroups/types';
import { selectCurrentOrgGUID, selectUserDetails } from 'RioRedux/common/selectors';
import { getUsageDetails, setUsageDetails } from 'RioRedux/usage/actions';
import { CommunityV2Plan } from 'Root/organization/constant';
import { inviteUserApi, removeUserApi } from 'Shared/api';
import { getMessageFromError } from 'Shared/utils/common';
import { diff, getValueByKey } from 'Shared/utils/core';
import { shouldShowBanners, shouldShowTrialPeriodBanner } from 'Shared/utils/user';
import { CallReturnType } from 'Types/saga';

import { saveUserDetails, setShowBanner } from '../common/actions';
import {
  dismissNotification,
  setNotificationFail,
  setNotificationInfo,
  setNotificationSuccess,
} from '../notifications/action';

import {
  clearInviteUserEmail,
  getOrganization as getOrganizationAction,
  saveOrgDetails,
  setCountryAPIStatus,
  setCreateUserGroupAPIStatus,
  setDeleteUserGroupAPIStatus,
  setDetailsError,
  setDetailsLoader,
  setError,
  setLeaveUserGroupAPIStatus,
  setProvinceAPIStatus,
  setRegistrationStatus,
  setToggleUserStateAPIStatus,
  setUpdateUserGroupAPIStatus,
  setUserGroupDetailsAPIStatus,
  setUserGroupsAPIStatus,
  updateCountryList,
  updateProvinceMap,
  updateUserGroupDetails,
  updateUserGroupsList,
} from './actions';
import ActionTypes from './actionTypes';
import { selectProvinceMap } from './selectors';

const createErrorPayload = (error: AxiosError): NotificationPayload => ({
  message: error?.message,
  description: getMessageFromError(error),
});

function* getOrganizationDetails(action: OrgAction<string>) {
  try {
    yield put(setDetailsLoader());
    const userDetails: ReturnType<typeof selectUserDetails> = yield select(selectUserDetails);
    const selectedOrgGUID: ReturnType<typeof selectCurrentOrgGUID> = yield select(
      selectCurrentOrgGUID,
    );
    const data: CallReturnType<typeof getOrganizationDetailsAPI> = yield call(
      getOrganizationDetailsAPI,
      action.payload,
    );
    yield put(saveOrgDetails(data));

    const isSelectedOrgPrimary = selectedOrgGUID === userDetails.organization?.guid;
    if (
      userDetails.roleInOrganization === UserRoles.ADMIN &&
      isSelectedOrgPrimary &&
      (userDetails.state === EntityState.ACTIVATED || userDetails.state === EntityState.SUSPENDED)
    ) {
      // TODO: Remove the eslint check once the usage actions are migrated to redux-saga
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      yield put(getUsageDetails(action.payload) as any);
    } else {
      yield put(setUsageDetails({}));
    }

    const isTrialPeriodBannerVisible = shouldShowTrialPeriodBanner(data.planMetaData, userDetails);
    const areBannersVisible = shouldShowBanners(userDetails.state);
    yield put(setShowBanner(isTrialPeriodBannerVisible || areBannersVisible));
  } catch {
    yield put(setDetailsError());
  }
}

function* getCountryList() {
  try {
    yield put(setCountryAPIStatus(APICallStatus.LOADING));

    const countries: CallReturnType<typeof getCountryListAPI> = yield call(getCountryListAPI);
    countries.sort((former, latter) => former.name.localeCompare(latter.name));
    yield put(updateCountryList(countries));

    yield put(setCountryAPIStatus(APICallStatus.LOADED));
  } catch {
    yield put(setCountryAPIStatus(APICallStatus.ERROR));
  }
}

function* getProvinceList(action: OrgAction<number>) {
  try {
    const provinceMap: ReturnType<typeof selectProvinceMap> = yield select(selectProvinceMap);

    if (provinceMap[action.payload]?.length) {
      return;
    }

    yield put(setProvinceAPIStatus(APICallStatus.LOADING));

    const data: CallReturnType<typeof getProvinceListAPI> = yield call(
      getProvinceListAPI,
      action.payload,
    );
    yield put(updateProvinceMap({ countryID: action.payload, options: data }));

    yield put(setProvinceAPIStatus(APICallStatus.LOADED));
  } catch {
    yield put(setProvinceAPIStatus(APICallStatus.ERROR));
  }
}

function* register(action: OrgAction<Partial<Organization>>) {
  try {
    yield put(setRegistrationStatus(OrgRegistrationStatus.REGISTER_ORG_PENDING));

    const orgData: CallReturnType<typeof registerAPI> = yield call(registerAPI, action.payload);

    const paymentInfoParams: PaymentInfoParams = {
      orgGUID: orgData.guid,
      planID: CommunityV2Plan.ID,
    };

    yield call(submitPaymentInfo, paymentInfoParams);

    yield put(setRegistrationStatus(OrgRegistrationStatus.REGISTER_ORG_SUCCESS));
    window.location.reload();
  } catch (err) {
    const error = err as AxiosError;
    const message = error.response?.data.error || error.message;
    yield put(setRegistrationStatus(OrgRegistrationStatus.REGISTER_ORG_FAILURE));
    yield put(setError(message));
  }
}

function* getUserGroups() {
  try {
    yield put(setUserGroupsAPIStatus(APICallStatus.LOADING));

    const userGroups: CallReturnType<typeof getUserGroupsList> = yield call(getUserGroupsList);
    yield put(updateUserGroupsList(userGroups));

    yield put(setUserGroupsAPIStatus(APICallStatus.LOADED));
  } catch (error) {
    yield put(setUserGroupsAPIStatus(APICallStatus.ERROR));
    const errorPayload = createErrorPayload(error as AxiosError);
    yield put(setNotificationFail(errorPayload));
  }
}

function* getUserGroupDetails(action: OrgAction<string>) {
  try {
    yield put(setUserGroupDetailsAPIStatus(APICallStatus.LOADING));

    const userGroupDetails: CallReturnType<typeof getUserGroupDetailsAPI> = yield call(
      getUserGroupDetailsAPI,
      action.payload,
    );
    yield put(updateUserGroupDetails(userGroupDetails));

    yield put(setUserGroupDetailsAPIStatus(APICallStatus.LOADED));
  } catch (error) {
    yield put(setUserGroupDetailsAPIStatus(APICallStatus.ERROR));
    const errorPayload = createErrorPayload(error as AxiosError);
    yield put(setNotificationFail(errorPayload));
  }
}

function* createUserGroup(action: OrgAction<CreateUserGroupData>) {
  try {
    yield put(setCreateUserGroupAPIStatus(APICallStatus.LOADING));

    const { formData, selectedUsers, selectedProjects } = action.payload;
    const mapGuid = (str: string) => ({ guid: str });

    const members = getValueByKey<User>(selectedUsers, 'guid') as string[];
    const projects = getValueByKey<Project>(selectedProjects, 'guid') as string[];
    const filteredAdmins = selectedUsers.filter(({ isAdmin }) => isAdmin);
    const admins = getValueByKey<User>(filteredAdmins, 'guid') as string[];
    const userGroupRoleInProjects = selectedProjects.map(({ guid, role }) => ({
      projectGUID: guid,
      groupRole: role,
    }));

    const req: CreateUserGroupReq = {
      ...formData,
      members: members.map(mapGuid),
      projects: projects.map(mapGuid),
      admins: admins.map(mapGuid),
      userGroupRoleInProjects,
    };
    yield call(createUserGroupAPI, req);
    const successPayload: NotificationPayload = {
      message: `${formData.name} created successfully!`,
    };
    yield put(setNotificationSuccess(successPayload));
    yield put(setCreateUserGroupAPIStatus(APICallStatus.LOADED));
    yield put(push('/organization/user-groups'));
  } catch (error) {
    yield put(setCreateUserGroupAPIStatus(APICallStatus.ERROR));
    const errorPayload = createErrorPayload(error as AxiosError);
    yield put(setNotificationFail(errorPayload));
  }
}

function* updateUserGroup(action: OrgAction<UpdateUserGroupData>) {
  try {
    yield put(setUpdateUserGroupAPIStatus(APICallStatus.LOADING));

    const {
      userGroup: { guid, members, projects, admins },
      formData,
      selectedUserKeys,
      selectedProjectKeys,
      selectedAdminKeys,
      userGroupRoleInProjects,
    } = action.payload;

    const adminKeys = getValueByKey<User>(admins, 'guid') as string[];
    const memberKeys = getValueByKey<User>(members, 'guid') as string[];
    const projectKeys = getValueByKey<UserProject>(projects as UserProject[], 'guid') as string[];

    const mapGuid = (str: string) => ({ guid: str });

    const addMembers = diff(selectedUserKeys, memberKeys).map(mapGuid);
    const removeMembers = diff(memberKeys, selectedUserKeys).map(mapGuid);

    const addProjects = diff(selectedProjectKeys, projectKeys).map(mapGuid);
    const removeProjects = diff(projectKeys, selectedProjectKeys).map(mapGuid);

    const addAdmins = diff(selectedAdminKeys, adminKeys).map(mapGuid);
    const removeAdmins = diff(adminKeys, selectedAdminKeys).map(mapGuid);

    const req = {
      ...formData,
      guid,
      update: {
        members: { add: addMembers, remove: removeMembers },
        projects: { add: addProjects, remove: removeProjects },
        admins: { add: addAdmins, remove: removeAdmins },
      },
      userGroupRoleInProjects,
    };

    yield call(updateUserGroupAPI, req);
    const successPayload: NotificationPayload = {
      message: `${formData.name} updated successfully!`,
    };
    yield put(setNotificationSuccess(successPayload));
    yield put(setUpdateUserGroupAPIStatus(APICallStatus.LOADED));
    yield put(push('/organization/user-groups'));
  } catch (error) {
    yield put(setUpdateUserGroupAPIStatus(APICallStatus.ERROR));
    const errorPayload = createErrorPayload(error as AxiosError);
    yield put(setNotificationFail(errorPayload));
  }
}

function* deleteUserGroup(action: OrgAction<UserGroup>) {
  try {
    yield put(setDeleteUserGroupAPIStatus(APICallStatus.LOADING));

    yield call(deleteUserGroupAPI, action?.payload.guid);
    yield put(setDeleteUserGroupAPIStatus(APICallStatus.LOADED));
    const successPayload: NotificationPayload = {
      message: `${action.payload.name} deleted successfully!`,
    };
    yield put(setNotificationSuccess(successPayload));
  } catch (error) {
    yield put(setDeleteUserGroupAPIStatus(APICallStatus.ERROR));
    const errorPayload = createErrorPayload(error as AxiosError);
    yield put(setNotificationFail(errorPayload));
  }
}

function* leaveUserGroup(action: OrgAction<UserGroup>) {
  try {
    yield put(setLeaveUserGroupAPIStatus(APICallStatus.LOADING));

    yield call(leaveUserGroupAPI, action?.payload.guid);
    yield put(setLeaveUserGroupAPIStatus(APICallStatus.LOADED));
    const successPayload: NotificationPayload = {
      message: `${action.payload.name} left successfully!`,
    };
    yield put(setNotificationSuccess(successPayload));
  } catch (error) {
    yield put(setLeaveUserGroupAPIStatus(APICallStatus.ERROR));
    const errorPayload = createErrorPayload(error as AxiosError);
    yield put(setNotificationFail(errorPayload));
  }
}

function* getOrganization(action: OrgAction<string>) {
  try {
    const orgData: CallReturnType<typeof getOrganizationDetailsAPI> = yield call(
      getOrganizationDetailsAPI,
      action.payload,
    );
    yield put(saveOrgDetails(orgData));
  } catch (e) {
    yield put(setDetailsError());
  }
}

function* inviteUser(action: OrgAction<string>) {
  try {
    const inviteUserEmail = action.payload;
    const orgGUID: ReturnType<typeof selectCurrentOrgGUID> = yield select(selectCurrentOrgGUID);

    const successPayload: NotificationPayload = {
      message: `Adding Invite for User ${inviteUserEmail}`,
    };
    yield put(setNotificationSuccess(successPayload));

    yield call(inviteUserApi, orgGUID, inviteUserEmail);
    yield put(getOrganizationAction(orgGUID ?? ''));
    yield put(clearInviteUserEmail());
  } catch (err) {
    const errorMsg = getMessageFromError(err);
    if (errorMsg) {
      const errorPayload: NotificationPayload = {
        message: 'Invitation Not Sent',
        description: errorMsg,
      };
      yield put(setNotificationFail(errorPayload));
      return;
    }

    const errorPayload: NotificationPayload = {
      message: 'Invitation Not Sent',
      description: 'Error in inviting the user. Please try again.',
    };
    yield put(setNotificationFail(errorPayload));
  }
}

function* removeUser(action: OrgAction<string>) {
  try {
    const orgGUID: ReturnType<typeof selectCurrentOrgGUID> = yield select(selectCurrentOrgGUID);

    const now: Date = new Date();
    const key: string = now.toISOString();
    const successPayload: NotificationPayload = {
      message: `Removing User: ${action.payload}`,
      key,
    };

    yield put(setNotificationInfo(successPayload));
    yield call(removeUserApi, orgGUID, action.payload);

    yield put(dismissNotification(key));
    yield put(
      setNotificationSuccess({
        message: `Sucessfully Removed ${action.payload}`,
      }),
    );
    yield put(getOrganizationAction(orgGUID ?? ''));
  } catch (error) {
    const errorPayload: NotificationPayload = {
      message: 'User Not Removed',
      description: 'Error occurred while removing the user. Please try again.',
    };
    yield put(setNotificationFail(errorPayload));
  }
}

function* toggleUserState(action: OrgAction<ToggleUserStatePayload>) {
  const getUserState: Partial<Record<EntityState, string>> = {
    [EntityState.ACTIVATED]: 'activate',
    [EntityState.DEACTIVATED]: 'deactivate',
  };

  try {
    yield put(setToggleUserStateAPIStatus(APICallStatus.LOADING));
    yield call(toggleUserStateAPI, action.payload);

    const orgGUID: ReturnType<typeof selectCurrentOrgGUID> = yield select(selectCurrentOrgGUID);
    const userDetails: CallReturnType<typeof getUserDetails> = yield call(getUserDetails);

    const stateInCurrentOrg = userDetails.userStateInOrgs.find(
      ({ organizationGUID }) => organizationGUID === orgGUID,
    )?.userState;

    if (stateInCurrentOrg === EntityState.DEACTIVATED) {
      yield put(saveUserDetails(userDetails));
      return;
    }

    yield put(getOrganizationAction(orgGUID));
    yield put(setToggleUserStateAPIStatus(APICallStatus.LOADED));

    const successPayload: NotificationPayload = {
      message: `User ${action.payload.state.toLowerCase()} succesfully`,
    };
    yield put(setNotificationSuccess(successPayload));
  } catch (error) {
    yield put(setToggleUserStateAPIStatus(APICallStatus.ERROR));
    const errorDesc = getMessageFromError(error);

    const errorNotificationPayload: NotificationPayload = {
      message: `Failed to ${getUserState[action.payload.state]} user`,
      description: errorDesc,
    };
    yield put(setNotificationFail(errorNotificationPayload));
  }
}

export default function* organizationSaga(): IterableIterator<ForkEffect<never>> {
  yield takeLatest(ActionTypes.GET_DETAILS, getOrganizationDetails);
  yield takeLatest(ActionTypes.GET_COUNTRY_LIST, getCountryList);
  yield takeLatest(ActionTypes.GET_PROVINCE_LIST, getProvinceList);
  yield takeLatest(ActionTypes.GET_USERGROUPS_LIST, getUserGroups);
  yield takeLatest(ActionTypes.GET_USERGROUP_DETAILS, getUserGroupDetails);
  yield takeLatest(ActionTypes.CREATE_USERGROUP, createUserGroup);
  yield takeLatest(ActionTypes.UPDATE_USERGROUP, updateUserGroup);
  yield takeLatest(ActionTypes.DELETE_USERGROUP, deleteUserGroup);
  yield takeLatest(ActionTypes.LEAVE_USERGROUP, leaveUserGroup);
  yield takeLatest(ActionTypes.REGISTER, register);
  yield takeLatest(ActionTypes.GET_ORGANIZATION, getOrganization);
  yield takeLatest(ActionTypes.INVITE_USER, inviteUser);
  yield takeLatest(ActionTypes.REMOVE_USER, removeUser);
  yield takeLatest(ActionTypes.TOGGLE_USER_STATE, toggleUserState);
}
