enum ActionTypes {
  SET_SELECTED_DEVICE_ID = 'SET_SELECTED_DEVICE_ID',

  ADD_DEVICE_MODAL_OPEN = 'ADD_DEVICE_MODAL_OPEN',
  ADD_DEVICE_MODAL_CLOSE = 'ADD_DEVICE_MODAL_CLOSE',

  ADD_DEVICE_DELETE_IN_USE_WARNING = 'ADD_DEVICE_DELETE_IN_USE_WARNING',
  REMOVE_DEVICE_DELETE_IN_USE_WARNING = 'REMOVE_DEVICE_DELETE_IN_USE_WARNING',

  DEVICE_ADD_PENDING = 'DEVICE_ADD_PENDING',
  DEVICE_ADD_FULFILLED = 'DEVICE_ADD_FULFILLED',
  DEVICE_ADD_REJECTED = 'DEVICE_ADD_REJECTED',

  DEVICE_DELETE_PENDING = 'DEVICE_DELETE_PENDING',
  DEVICE_DELETE_FULFILLED = 'DEVICE_DELETE_FULFILLED',
  DEVICE_DELETE_REJECTED = 'DEVICE_DELETE_REJECTED',

  DEVICES_GET_PENDING = 'DEVICES_GET_PENDING',
  DEVICES_GET_FULFILLED = 'DEVICES_GET_FULFILLED',
  DEVICES_GET_REJECTED = 'DEVICES_GET_REJECTED',

  UPDATE_DEVICE_PENDING = 'UPDATE_DEVICE_PENDING',
  UPDATE_DEVICE_FULFILLED = 'UPDATE_DEVICE_FULFILLED',
  UPDATE_DEVICE_REJECTED = 'UPDATE_DEVICE_REJECTED',

  DEVICE_TOPICS_GET_PENDING = 'DEVICE_TOPICS_GET_PENDING',
  DEVICE_TOPICS_GET_FULFILLED = 'DEVICE_TOPICS_GET_FULFILLED',
  DEVICE_TOPICS_UPDATE_MASTER_UP = 'DEVICE_TOPICS_UPDATE_MASTER_UP',
  DEVICE_TOPICS_GET_REJECTED = 'DEVICE_TOPICS_GET_REJECTED',

  DEVICE_TOPIC_SUBSCRIBE_PENDING = 'DEVICE_TOPIC_SUBSCRIBE_PENDING',
  DEVICE_TOPIC_SUBSCRIBE_FULFILLED = 'DEVICE_TOPIC_SUBSCRIBE_FULFILLED',
  DEVICE_TOPIC_SUBSCRIBE_REJECTED = 'DEVICE_TOPIC_SUBSCRIBE_REJECTED',

  PACKAGE_DEPLOYMENT_DEVICES_GET_PENDING = 'PACKAGE_DEPLOYMENT_DEVICES_GET_PENDING',
  PACKAGE_DEPLOYMENT_DEVICES_GET_FULFILLED = 'PACKAGE_DEPLOYMENT_DEVICES_GET_FULFILLED',
  PACKAGE_DEPLOYMENT_DEVICES_GET_REJECTED = 'PACKAGE_DEPLOYMENT_DEVICES_GET_REJECTED',

  DEVICE_DETAILS_GET_PENDING = 'DEVICE_DETAILS_GET_PENDING',
  DEVICE_DETAILS_GET_FULFILLED = 'DEVICE_DETAILS_GET_FULFILLED',
  DEVICE_DETAILS_GET_REJECTED = 'DEVICE_DETAILS_GET_REJECTED',

  DEVICE_PAGE_SET_ID = 'DEVICE_PAGE_SET_ID',
  TOGGLE_DEVICE_NAME_EDIT_MODE = 'TOGGLE_DEVICE_NAME_EDIT_MODE',
  DIRTY_DEVICE_NAME = 'DIRTY_DEVICE_NAME',

  TOGGLE_DEVICE_DESCRIPTION_EDIT_MODE = 'TOGGLE_DEVICE_DESCRIPTION_EDIT_MODE',
  DIRTY_DEVICE_DESCRIPTION = 'DIRTY_DEVICE_DESCRIPTION',

  DEVICE_DIRTY_ADD_LABEL_KEY = 'DEVICE_DIRTY_ADD_LABEL_KEY',
  DEVICE_DIRTY_ADD_LABEL_VALUE = 'DEVICE_DIRTY_ADD_LABEL_VALUE',

  DEVICE_TOPIC_UNSUBSCRIBE_PENDING = 'DEVICE_TOPIC_UNSUBSCRIBE_PENDING',
  DEVICE_TOPIC_UNSUBSCRIBE_FULFILLED = 'DEVICE_TOPIC_UNSUBSCRIBE_FULFILLED',
  DEVICE_TOPIC_UNSUBSCRIBE_REJECTED = 'DEVICE_TOPIC_UNSUBSCRIBE_REJECTED',

  GET_DEVICE_TOKEN_FULFILLED = 'GET_DEVICE_TOKEN_FULFILLED',
  GET_DEVICE_TOKEN_MODAL_FULFILLED = 'GET_DEVICE_TOKEN_MODAL_FULFILLED',
  GET_DEVICE_TOKEN_REJECTED = 'GET_DEVICE_TOKEN_REJECTED',

  DEVICE_ADD_LABEL_PENDING = 'DEVICE_ADD_LABEL_PENDING',
  DEVICE_ADD_LABEL_FULFILLED = 'DEVICE_ADD_LABEL_FULFILLED',
  DEVICE_ADD_LABEL_REJECTED = 'DEVICE_ADD_LABEL_REJECTED',

  DEVICE_DIRTY_LABEL_KEY = 'DEVICE_DIRTY_LABEL_KEY',
  DEVICE_DIRTY_LABEL_VALUE = 'DEVICE_DIRTY_LABEL_VALUE',

  DEVICE_LABEL_UPDATE_PENDING = 'DEVICE_LABEL_UPDATE_PENDING',
  DEVICE_LABEL_UPDATE_FULFILLED = 'DEVICE_LABEL_UPDATE_FULFILLED',
  DEVICE_LABEL_UPDATE_REJECTED = 'DEVICE_LABEL_UPDATE_REJECTED',

  DEVICE_LABEL_DELETE_PENDING = 'DEVICE_LABEL_DELETE_PENDING',
  DEVICE_LABEL_DELETE_FULFILLED = 'DEVICE_LABEL_DELETE_FULFILLED',
  DEVICE_LABEL_DELETE_REJECTED = 'DEVICE_LABEL_DELETE_REJECTED',

  DEVICE_UNAME_GET_PENDING = 'DEVICE_UNAME_GET_PENDING',
  DEVICE_UNAME_GET_FULFILLED = 'DEVICE_UNAME_GET_FULFILLED',
  DEVICE_UNAME_GET_REJECTED = 'DEVICE_UNAME_GET_REJECTED',

  DEVICE_COMMAND_POST_PENDING = 'DEVICE_COMMAND_POST_PENDING',
  DEVICE_COMMAND_POST_FULFILLED = 'DEVICE_COMMAND_POST_FULFILLED',
  DEVICE_COMMAND_POST_REJECTED = 'DEVICE_COMMAND_POST_REJECTED',

  DEVICE_NAME_UPDATE_PENDING = 'DEVICE_NAME_UPDATE_PENDING',
  DEVICE_NAME_UPDATE_FULFILLED = 'DEVICE_NAME_UPDATE_FULFILLED',
  DEVICE_NAME_UPDATE_REJECTED = 'DEVICE_NAME_UPDATE_REJECTED',

  DEVICE_DESCRIPTION_UPDATE_PENDING = 'DEVICE_DESCRIPTION_UPDATE_PENDING',
  DEVICE_DESCRIPTION_UPDATE_FULFILLED = 'DEVICE_DESCRIPTION_UPDATE_FULFILLED',
  DEVICE_DESCRIPTION_UPDATE_REJECTED = 'DEVICE_DESCRIPTION_UPDATE_REJECTED',

  SET_LOGS_API_STATUS = 'DEVICE/SET_LOGS_API_STATUS',
  SET_LOGS_DATA = 'DEVICE/SET_LOGS_DATA',

  SET_MIGRATE_DEVICE_API_STATUS = 'SET_MIGRATE_DEVICE_API_STATUS',

  SET_DAEMONS = 'SET_DAEMONS',
  SET_DAEMONS_API_STATUS = 'SET_DAEMONS_API_STATUS',
}

export default ActionTypes;
