import React, { lazy } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import { AUTH_LANDING_PAGE_ROUTE, LOGIN_ROUTE } from 'Constants/layout';
import { AppState, EntityState, UserRoles } from 'Models/common/types';
import { Project } from 'Models/projects/types';
import { PathParamsEnum } from 'Root/types';

import AuthenticatedRotue from './AuthRoute';

const Login = lazy(() => import('Components/login'));
const LandingPage = lazy(() => import('Root/landingPage'));
const Dashboard = lazy(() => import('Root/Dashboard'));
const Organization = lazy(() => import('Root/organization'));
const CreateUserGroup = lazy(() => import('Root/organization/CreateUserGroup'));
const ProjectsList = lazy(() => import('Root/projects/ProjectList'));
const PackagesList = lazy(() => import('Root/packages/PackageList'));
const PackageDetails = lazy(() => import('Root/packages/PackageDetails'));
const DeviceList = lazy(() => import('Root/devices/DeviceList'));
const DeviceDetails = lazy(() => import('Root/devices/details/containers'));
const Configurations = lazy(() => import('Root/configurations/ConfigurationList'));
const ConfigurationDetails = lazy(() => import('Root/configurations/details/containers'));
const ResourcesList = lazy(() => import('Root/additonal_resources'));
const DeploymentsList = lazy(() => import('Root/deployments/DeploymentsList'));
const DeploymentDetails = lazy(() => import('Root/deployments/DeploymentDetails/'));
const StaticRoutesList = lazy(() => import('Root/staticRoutes/'));
const NetworkListContainer = lazy(() => import('Root/networks/list'));
const DiskListContainer = lazy(() => import('Root/disks/list'));
const NonExistent = lazy(() => import('Components/404'));
const Profile = lazy(() => import('Root/profile'));
const DeactivatedUser = lazy(() => import('Root/DeactivatedUser'));
interface Props {
  accountState: EntityState;
  projects: Project[];
  roleInOrganization?: UserRoles;
  isSelectedOrgPrimary: boolean;
  isUserDeactivatedInOrg: boolean;
  isUserActivatedInOrg: boolean;
  selectedProjectGUID: string;
}

const Routes: React.FC<Props> = (props) => {
  const projectLength = props.projects.length;

  const deactivatedUserInOrgView = props.isUserDeactivatedInOrg;
  const activationRequiredView = props.isUserActivatedInOrg;
  const activationRequiredWithProjectsView = activationRequiredView && projectLength > 0;
  const activationRequiredWithoutProjectsView = activationRequiredView && projectLength === 0;

  const defaultView =
    activationRequiredWithProjectsView &&
    Boolean(props.projects.find((x: Project) => x.guid === props.selectedProjectGUID));

  return (
    <Switch>
      <Switch>
        <Route component={Login} path={LOGIN_ROUTE} />
        <Route
          path="/kompose"
          render={() => {
            window.location.reload();
            return null;
          }}
        />
        <Route exact path="/" component={LandingPage} />

        {deactivatedUserInOrgView && (
          <>
            <Redirect to="/deactivated" />
            <AuthenticatedRotue component={DeactivatedUser} path="/deactivated" />
          </>
        )}
        {!deactivatedUserInOrgView && <Redirect exact path="/deactivated" to="/dashboard" />}

        <AuthenticatedRotue component={Profile} exact path="/profile" />
        <AuthenticatedRotue component={Dashboard} exact path={AUTH_LANDING_PAGE_ROUTE} />

        {activationRequiredView && (
          <AuthenticatedRotue component={CreateUserGroup} path="/organization/create-user-group" />
        )}

        {activationRequiredView && (
          <AuthenticatedRotue component={Organization} path="/organization" />
        )}

        {/* After activation but without projects */}
        {activationRequiredWithoutProjectsView && (
          <Redirect exact path="/additional_resources" to="/additional_resources/source_secrets" />
        )}
        {activationRequiredWithoutProjectsView && (
          <AuthenticatedRotue component={ProjectsList} path="*" />
        )}

        {/* After activation and with projects list */}
        {activationRequiredWithProjectsView && (
          <AuthenticatedRotue component={ProjectsList} exact path="/projects" />
        )}

        {/* After activation and with projects list and a selected project */}
        {defaultView && <AuthenticatedRotue component={PackagesList} exact path="/packages" />}
        {defaultView && (
          <AuthenticatedRotue
            path="/packages/:name/version/:version([A-Za-z0-9.-]*)"
            exact
            component={PackageDetails}
          />
        )}
        {defaultView && <AuthenticatedRotue component={DeviceList} exact path="/devices" />}
        {defaultView && (
          <AuthenticatedRotue
            component={DeviceDetails}
            path={`/devices/:${PathParamsEnum.DEVICE_ID}`}
          />
        )}

        {defaultView && (
          <AuthenticatedRotue component={Configurations} exact path="/configurations" />
        )}
        {defaultView && (
          <AuthenticatedRotue
            component={ConfigurationDetails}
            path="/configurations/:configuration_id"
          />
        )}

        {defaultView && (
          <AuthenticatedRotue component={ResourcesList} exact path="/additional_resources" />
        )}
        {defaultView && (
          <AuthenticatedRotue component={ResourcesList} path="/additional_resources/*" />
        )}
        {defaultView && (
          <AuthenticatedRotue component={DeploymentsList} exact path="/deployments" />
        )}
        {defaultView && (
          <AuthenticatedRotue component={DeploymentDetails} path="/deployments/:deploymentName" />
        )}
        {defaultView && (
          <AuthenticatedRotue component={StaticRoutesList} exact path="/static_routes" />
        )}
        {defaultView && (
          <AuthenticatedRotue component={NetworkListContainer} exact path="/network_resource" />
        )}
        {defaultView && <AuthenticatedRotue component={DiskListContainer} exact path="/disks" />}

        {/* Unmatched */}
        <AuthenticatedRotue component={NonExistent} />
      </Switch>
    </Switch>
  );
};

const mapState = ({ common }: AppState) => ({ selectedProjectGUID: common.selectedProjectGUID });

export default connect(mapState)(Routes);
