import axios from 'axios';

import { authAxios } from './utils/user';
import { CHANGE_ADDONS_URL, CORE_ROOT_URL, ORG_ROOT_URL } from './config';

export const getTosApi = async () =>
  axios({
    method: 'GET',
    url: '/tos',
  });

export const createUserApi = async () =>
  authAxios({
    method: 'POST',
    url: `${CORE_ROOT_URL}api/user/create`,
    data: {},
  });

export const inviteUserApi = async (orgGUID, userEmail) =>
  authAxios({
    method: 'PUT',
    url: `${ORG_ROOT_URL}${orgGUID}/adduser`,
    data: {
      userEmail,
    },
  });

export const removeUserApi = async (orgGUID, userEmail) =>
  authAxios({
    method: 'DELETE',
    url: `${ORG_ROOT_URL}${orgGUID}/removeuser`,
    data: {
      userEmail,
    },
  });

export const updateAddOnsApi = ({ uomName, newAddonsDelta, userGuid, organizationGUID }) =>
  authAxios({
    method: 'POST',
    url: CHANGE_ADDONS_URL,
    data: {
      userGuid,
      op: newAddonsDelta > 0 ? 'UnitAddition' : 'UnitDeletion',
      quantity: Math.abs(newAddonsDelta),
      unitOfMeasure: uomName,
      organizationGUID,
    },
  });
