import { APICallStatus } from 'Models/common/types';
import {
  Country,
  OrgAction,
  OrgActionPayload,
  Organization,
  OrgRegistrationStatus,
  OrgState,
  UpdateProvinceMapPayload,
} from 'Models/organization/types';
import { UserGroup, UserGroupWithProjectRole } from 'Models/userGroups/types';

import ActionTypes from './actionTypes';

const initialOrgDetails: Organization = {
  creator: '',
  guid: '',
  name: '',
  planID: null,
  projects: null,
  state: null,
  url: '',
  users: [],
};

export const initialState: OrgState = {
  apiStatus: APICallStatus.INITIAL,
  error: '',
  details: initialOrgDetails,
  countries: [],
  userGroups: [],
  userGroupDetails: null,
  userGroupsAPIStatus: APICallStatus.INITIAL,
  createUserGroupAPIStatus: APICallStatus.INITIAL,
  updateUserGroupAPIStatus: APICallStatus.INITIAL,
  deleteUserGroupAPIStatus: APICallStatus.INITIAL,
  leaveUserGroupAPIStatus: APICallStatus.INITIAL,
  userGroupDetailsAPIStatus: APICallStatus.INITIAL,
  countryAPIStatus: APICallStatus.INITIAL,
  toggleStateAPIStatus: APICallStatus.INITIAL,
  registrationStatus: OrgRegistrationStatus.INITIAL,
  provinceAPIStatus: APICallStatus.INITIAL,
  provinceMap: {},
  inviteUserEmail: '',
};

const organizationReducer = (
  state: OrgState = initialState,
  action: OrgAction<OrgActionPayload>,
): OrgState => {
  switch (action.type) {
    case ActionTypes.SET_DETAILS_LOADING: {
      return {
        ...state,
        apiStatus: APICallStatus.LOADING,
        error: '',
        details: initialOrgDetails,
      };
    }

    case ActionTypes.UPDATE_DETAILS: {
      return {
        ...state,
        apiStatus: APICallStatus.LOADED,
        details: action.payload as Organization,
      };
    }

    case ActionTypes.SET_DETAILS_ERROR: {
      return {
        ...state,
        apiStatus: APICallStatus.ERROR,
        error: 'Failed to fetch organization details',
        details: initialOrgDetails,
      };
    }

    case ActionTypes.SET_COUNTRY_API_STATUS: {
      return {
        ...state,
        countryAPIStatus: action.payload as APICallStatus,
      };
    }

    case ActionTypes.SET_USERGROUPS_API_STATUS: {
      return {
        ...state,
        userGroupsAPIStatus: action.payload as APICallStatus,
      };
    }

    case ActionTypes.SET_CREATE_USERGROUP_API_STATUS: {
      return {
        ...state,
        createUserGroupAPIStatus: action.payload as APICallStatus,
      };
    }

    case ActionTypes.SET_UPDATE_USERGROUP_API_STATUS: {
      return {
        ...state,
        updateUserGroupAPIStatus: action.payload as APICallStatus,
      };
    }

    case ActionTypes.SET_DELETE_USERGROUP_API_STATUS: {
      return {
        ...state,
        deleteUserGroupAPIStatus: action.payload as APICallStatus,
      };
    }

    case ActionTypes.SET_LEAVE_USERGROUP_API_STATUS: {
      return {
        ...state,
        leaveUserGroupAPIStatus: action.payload as APICallStatus,
      };
    }

    case ActionTypes.UPDATE_COUNTRY_LIST: {
      return {
        ...state,
        countries: action.payload as Country[],
      };
    }

    case ActionTypes.UPDATE_USERGROUPS_LIST: {
      return {
        ...state,
        userGroups: action.payload as UserGroup[],
      };
    }

    case ActionTypes.UPDATE_USERGROUP_DETAILS: {
      return {
        ...state,
        userGroupDetails: action.payload as UserGroupWithProjectRole,
      };
    }

    case ActionTypes.SET_USERGROUP_DETAILS_API_STATUS: {
      return {
        ...state,
        userGroupDetailsAPIStatus: action.payload as APICallStatus,
      };
    }

    case ActionTypes.SET_REGISTRATION_STATUS: {
      return {
        ...state,
        registrationStatus: action.payload as OrgRegistrationStatus,
      };
    }

    case ActionTypes.SET_PROVINCE_API_STATUS: {
      return {
        ...state,
        provinceAPIStatus: action.payload as APICallStatus,
      };
    }

    case ActionTypes.UPDATE_PROVINCE_MAP: {
      const payload = action.payload as UpdateProvinceMapPayload;
      return {
        ...state,
        provinceMap: {
          ...state.provinceMap,
          [payload.countryID]: payload.options,
        },
      };
    }

    case ActionTypes.SET_ERROR: {
      return {
        ...state,
        error: action.payload as string,
      };
    }

    case ActionTypes.SET_INVITE_USER_EMAIL_INPUT: {
      return {
        ...state,
        inviteUserEmail: action.payload as string,
      };
    }

    case ActionTypes.CLEAR_INVITE_USER_EMAIL_INPUT: {
      return {
        ...state,
        inviteUserEmail: '',
      };
    }

    case ActionTypes.SET_TOGGLE_USER_STATE_API_STATUS: {
      return {
        ...state,
        toggleStateAPIStatus: action.payload as APICallStatus,
      };
    }

    default:
      return state;
  }
};

export default organizationReducer;
