import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers, Reducer } from 'redux';

import { AppState } from 'Models/common/types';

import common from './common/reducer';
import configurations from './configurations/reducer';
import deployments from './deployments/reducer';
import deployPackage from './deployPackage/reducer';
import devices from './devices/reducer';
import disks from './disks/reducer';
import metrics from './metrics/reducer';
import network from './network/reducer';
import notification from './notifications/reducer';
import organization from './organization/reducer';
import packages from './packages/reducer';
import projects from './projects/reducer';
import secrets from './secrets/reducer';
import staticRoutes from './staticRoutes/reducer';
import usage from './usage/reducer';

const allReducers = (history: History): Reducer<AppState> =>
  combineReducers({
    router: connectRouter(history),
    common,
    configurations,
    organization,
    projects,
    deployments,
    devices,
    disks,
    secrets,
    usage,
    staticRoutes,
    metrics,
    notification,
    packages,
    deployPackage,
    network,
  });

export default allReducers;
