import { AxiosError } from 'axios';
import { push } from 'connected-react-router';
import { nanoid } from 'nanoid';
import { call, ForkEffect, put, select, takeLatest } from 'redux-saga/effects';

import { APICallStatus, EntityState, RIOError } from 'Models/common/types';
import { NotificationPayload } from 'Models/notifications/types';
import {
  getErrorObj,
  getProjectCreationErrorMessage,
  mapCreateProjectFormDataToAPI,
} from 'Models/projects/dataFormatters';
import {
  createProject as createProjectAPI,
  deleteProject as deleteProjectAPI,
  editProject as editProjectAPI,
  getProjectDetails as getProjectDetailsAPI,
  getProjectsList,
  updateProjectOwner as updateProjectOwnerAPI,
} from 'Models/projects/service';
import {
  CreateProjectPayload,
  DeleteProjectPayload,
  EditProjectPayload,
  Project,
  ProjectsAction,
  ProjectUser,
  UpdateProjectOwnerPayload,
} from 'Models/projects/types';
import { getDevicesApi } from 'Root/models/devices/service';
import { getSecretsApi } from 'Root/models/secrets/service';
import { getMessageFromError } from 'Root/shared/utils/common';
import { getErrorMessage } from 'Shared/utils/core';
import storeUtilAdapterInst from 'Shared/utils/storeUtilsAdapter';
import { CallReturnType } from 'Types/saga';

import {
  changeSelectedProject,
  setUserMap,
  updateProjectsAndSelectedProject,
} from '../common/actions';
import { changeSelectedProjectMisc } from '../common/saga';
import {
  selectCurrentOrgGUID,
  selectCurrentProjectGUID,
  selectStateInCurrentOrg,
  selectUserDetails,
} from '../common/selectors';
import {
  dismissNotification,
  setNotificationFail,
  setNotificationInfo,
  setNotificationSuccess,
} from '../notifications/action';

import {
  clearProjectDetails,
  deleteProjectSuccess,
  listProjects as listProjectsAction,
  setCreateProjectError,
  setCreateProjectFulfilled,
  setDockerCacheDevicesList,
  setDockerCacheDevicesListAPICallStatus,
  setDockerCacheSecretList,
  setDockerCacheSecretListAPICallStatus,
  setEditProjectFulfilled,
  setProjectDetails,
  setProjectDetailsError,
  setProjectGetError,
  setProjectsAPIStatus,
  setProjectsLoader,
  updateProjectsList,
} from './actions';
import ActionTypes from './actionTypes';

function* updateProjectData(projectList: Project[], selectedProjectGUID: string) {
  changeSelectedProjectMisc(selectedProjectGUID);
  yield put(
    updateProjectsAndSelectedProject({
      projects: projectList,
      selectedProjectGUID,
    }),
  );
}

function* listProjects(action: ProjectsAction<string>) {
  const stateInCurrentOrg: ReturnType<typeof selectStateInCurrentOrg> = yield select(
    selectStateInCurrentOrg,
  );

  yield put(setProjectsLoader());

  try {
    let projects: CallReturnType<typeof getProjectsList> = [];

    const selectedOrgGUID: ReturnType<typeof selectCurrentOrgGUID> = yield select(
      selectCurrentOrgGUID,
    );

    if (
      stateInCurrentOrg !== EntityState.DEACTIVATED &&
      stateInCurrentOrg !== EntityState.SUSPENDED
    ) {
      projects = yield call(getProjectsList, selectedOrgGUID);
    }
    const currentOrgProjects = projects.sort((former, latter) =>
      former.name.toLowerCase().localeCompare(latter.name.toLowerCase()),
    );

    const projectID: string = storeUtilAdapterInst.getSelectedProjectGUID() ?? '';
    const projectDetails = currentOrgProjects.find((project) => project.guid === projectID);
    const loggedinGuid = action.payload as string;
    const projectToSelect = currentOrgProjects[0];
    let isProjectListUpdated = false;

    if (projectID?.length > 0) {
      const users = projectDetails?.users;
      const orgGUID = projectDetails?.organization;

      if (users?.some((user) => user.userGUID === loggedinGuid) || orgGUID === selectedOrgGUID) {
        if (projectID !== projectDetails?.guid) {
          isProjectListUpdated = true;
          yield call(updateProjectData, currentOrgProjects, projectDetails!.guid);
        }
      } else if (projectToSelect && projectID !== projectToSelect.guid) {
        isProjectListUpdated = true;
        yield call(updateProjectData, currentOrgProjects, projectToSelect?.guid);
      }
    } else if (!projectID?.length) {
      if (projectToSelect && projectID !== projectToSelect.guid) {
        isProjectListUpdated = true;
        yield call(updateProjectData, currentOrgProjects, projectToSelect?.guid);
      }
    }

    if (!isProjectListUpdated) {
      yield put(updateProjectsList(currentOrgProjects));
    }

    const usersArr = projects.map((project) => project.users);
    const userMap = usersArr.reduce((acc: ProjectUser[], users) => [...acc, ...(users ?? [])], []);
    yield put(setUserMap(userMap));
  } catch (err) {
    const error = err as AxiosError;
    if (!error.response) {
      yield put(setProjectGetError());
    }

    switch (error.response?.status) {
      case 401:
      case 403:
        yield put(updateProjectsList([]));
        break;
      default:
        break;
    }
  }
}

function* createProject(action: ProjectsAction<CreateProjectPayload>) {
  yield put(setProjectsAPIStatus(APICallStatus.LOADING));

  try {
    const mappedPayload = mapCreateProjectFormDataToAPI(action.payload as CreateProjectPayload);

    const data: CallReturnType<typeof createProjectAPI> = yield call(
      createProjectAPI,
      mappedPayload,
    );

    const projectId = data.guid;

    yield put(setProjectDetails(data));

    yield put(
      push({
        pathname: '/projects',
        search: `?showProject=${projectId}`,
      }),
    );

    yield put(setCreateProjectFulfilled());
    yield put(changeSelectedProject(projectId));

    const successPayload: NotificationPayload = {
      message: 'Project Successfully Created!',
    };
    yield put(setNotificationSuccess(successPayload));
  } catch (err) {
    const error = err as AxiosError;
    const errorDesc = getMessageFromError(err);

    const errorNotificationPayload: NotificationPayload = {
      message: `Failed To Create Project ${(action.payload as CreateProjectPayload).name}`,
      description: errorDesc,
    };
    yield put(setNotificationFail(errorNotificationPayload));

    const errorMessage = getProjectCreationErrorMessage(error);

    const errorPayload: RIOError = {
      status: error.response?.status,
      message: errorMessage,
    };

    yield put(setCreateProjectError(errorPayload));
  }
}

function* editProject(action: ProjectsAction<EditProjectPayload>) {
  yield put(setProjectsAPIStatus(APICallStatus.LOADING));

  try {
    const mappedPayload = mapCreateProjectFormDataToAPI(
      action.payload?.data as CreateProjectPayload,
    );

    const data: CallReturnType<typeof editProjectAPI> = yield call(
      editProjectAPI,
      mappedPayload,
      action.payload?.projectId as string,
    );

    const projectId = data.guid;

    /* Updation of vpn status is async.
     ** Hence enforcing project get is made when navigated to project details */
    yield put(clearProjectDetails());

    yield put(
      push({
        pathname: '/projects',
        search: `?showProject=${projectId}`,
      }),
    );

    yield put(setEditProjectFulfilled(projectId));
    yield put(setProjectsAPIStatus(APICallStatus.INITIAL));

    const userDetails: ReturnType<typeof selectUserDetails> = yield select(selectUserDetails);

    yield put(listProjectsAction(userDetails.guid));

    yield put(changeSelectedProject(projectId));

    const successPayload: NotificationPayload = {
      message: 'Project Successfully Updated!',
    };
    yield put(setNotificationSuccess(successPayload));
  } catch (err) {
    yield put(setProjectsAPIStatus(APICallStatus.ERROR));
    const error = err as AxiosError;
    const errorDesc = getMessageFromError(err);

    const errorNotificationPayload: NotificationPayload = {
      message: `Failed To Update Project ${(action.payload as EditProjectPayload).data.name}`,
      description: errorDesc,
    };

    yield put(setNotificationFail(errorNotificationPayload));
    const errorMessage = getProjectCreationErrorMessage(error);
    const errorPayload: RIOError = {
      status: error.response?.status,
      message: errorMessage,
    };
    yield put(setCreateProjectError(errorPayload));
  }
}

function* deleteProject(action: ProjectsAction<DeleteProjectPayload>) {
  const { name, id } = action.payload as DeleteProjectPayload;
  const notifKey = nanoid();

  yield put(
    setNotificationInfo({
      message: `Deleting Project ${name}`,
      key: notifKey,
    }),
  );

  try {
    yield put(setProjectsAPIStatus(APICallStatus.LOADING));
    yield call(deleteProjectAPI, id);

    yield put(dismissNotification(notifKey));
    yield put(
      setNotificationSuccess({
        message: `Deleted Project ${name}`,
      }),
    );

    const selectedProjectGUID: string = yield select(selectCurrentProjectGUID);

    if (selectedProjectGUID === id) {
      yield put(changeSelectedProject(''));
    } else {
      yield put(deleteProjectSuccess(name));
    }

    yield put(deleteProjectSuccess(name));
    yield put(setProjectsAPIStatus(APICallStatus.LOADED));
  } catch (err) {
    yield put(setProjectsAPIStatus(APICallStatus.ERROR));
    const error = err as AxiosError;
    yield put(dismissNotification(notifKey));
    yield put(
      setNotificationFail({
        message: `Failed to delete project ${name}`,
        description: getErrorMessage(error),
      }),
    );
  }
}

function* getProjectDetails(action: ProjectsAction<string>) {
  yield put(setProjectsAPIStatus(APICallStatus.LOADING));
  const projectID = action.payload as string;
  try {
    const data: CallReturnType<typeof getProjectDetailsAPI> = yield call(
      getProjectDetailsAPI,
      projectID,
    );

    yield put(setProjectDetails(data));

    yield put(setUserMap(data.users ?? []));
  } catch (err) {
    const error = getErrorObj(err as AxiosError);
    yield put(setProjectDetailsError(projectID, error));
  }
}

function* updateProjectOwner(action: ProjectsAction<UpdateProjectOwnerPayload>) {
  try {
    yield call(updateProjectOwnerAPI, action.payload as UpdateProjectOwnerPayload);

    const userDetails: ReturnType<typeof selectUserDetails> = yield select(selectUserDetails);

    yield put(
      setNotificationSuccess({
        message: `Project Owner Updated Successfully`,
      }),
    );
    yield put(listProjectsAction(userDetails.guid));
  } catch (err) {
    yield put(
      setNotificationFail({
        message: `Failed To Update Project Owner`,
      }),
    );
  }
}

function* getDockerCacheDevicesList(action: ProjectsAction<string>) {
  try {
    yield put(setDockerCacheDevicesListAPICallStatus(APICallStatus.LOADING));
    const { data }: CallReturnType<typeof getDevicesApi> = yield call(getDevicesApi, {
      headers: {
        project: action.payload,
      },
    });

    yield put(setDockerCacheDevicesList(data));
    yield put(setDockerCacheDevicesListAPICallStatus(APICallStatus.LOADED));
  } catch (error) {
    yield put(setDockerCacheDevicesListAPICallStatus(APICallStatus.ERROR));
    const err = error as AxiosError;
    const errorDesc = getMessageFromError(err);

    const errorPayload: NotificationPayload = {
      message: 'Error Getting Devices List',
      description: errorDesc,
    };
    yield put(setNotificationFail(errorPayload));
  }
}

function* getSecretList(action: ProjectsAction<string>) {
  try {
    yield put(setDockerCacheSecretListAPICallStatus(APICallStatus.LOADING));
    const { data }: CallReturnType<typeof getSecretsApi> = yield call(getSecretsApi, {
      headers: {
        project: action.payload,
      },
    });

    yield put(setDockerCacheSecretList(data));
    yield put(setDockerCacheSecretListAPICallStatus(APICallStatus.LOADED));
  } catch (error) {
    yield put(setDockerCacheSecretListAPICallStatus(APICallStatus.ERROR));
    const err = error as AxiosError;
    const errorDesc = getMessageFromError(err);

    const errorPayload: NotificationPayload = {
      message: 'Error Getting Devices List',
      description: errorDesc,
    };
    yield put(setNotificationFail(errorPayload));
  }
}

export default function* projectsSaga(): IterableIterator<ForkEffect<never>> {
  yield takeLatest(ActionTypes.GET_PROJECTS, listProjects);
  yield takeLatest(ActionTypes.CREATE_PROJECT, createProject);
  yield takeLatest(ActionTypes.EDIT_PROJECT, editProject);
  yield takeLatest(ActionTypes.DELETE_PROJECT, deleteProject);
  yield takeLatest(ActionTypes.GET_PROJECT_DETAILS, getProjectDetails);
  yield takeLatest(ActionTypes.UPDATE_PROJECT_OWNER, updateProjectOwner);
  yield takeLatest(ActionTypes.GET_DOCKER_CACHE_DEVICES_LIST, getDockerCacheDevicesList);
  yield takeLatest(ActionTypes.GET_DOCKER_CACHE_SECRETS_LIST, getSecretList);
}
