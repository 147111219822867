import React, { lazy, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Layout } from 'antd';

import InitialLoader from 'Components/initialLoader';
import {
  APICallStatus,
  AppState,
  EntityState,
  RIOError,
  User,
  UserRoles,
  UserStateOrgMap,
} from 'Models/common/types';
import { Organization } from 'Models/organization/types';
import { Project } from 'Models/projects/types';
import { getUserDetails } from 'RioRedux/common/actions';
import { getOrgDetails } from 'RioRedux/organization/actions';
import { listProjects } from 'RioRedux/projects/actions';
import { getUsageDetails } from 'RioRedux/usage/actions';
import { LAYOUT_ROOT_CLASS } from 'Shared/constants/layout';

import Routes from './Routes';

const { Content, Footer } = Layout;

const Register = lazy(() => import('./Register'));

interface Props {
  userToken: string;
  userDetails: User;
  isNewUser: boolean;
  userErrorMessage: string;
  projectListAPIStatus: APICallStatus;
  projectListError: RIOError | null;
  projects: Project[];
  orgDetailsAPIStatus: APICallStatus;
  orgDetailsError: string;
  orgDetails: Organization;
  usageAPIStatus: APICallStatus;
  usageError: string | null;
  selectedOrgGUID: string;
  userStateInOrgs: UserStateOrgMap[];
  getUserDetails: typeof getUserDetails;
  listProjects: (projectId: string) => void;
  getOrgDetails: typeof getOrgDetails;
  getUsageDetails: (orgId: string) => void;
}

const rootClassName = LAYOUT_ROOT_CLASS;
const pageContainerClassName = `${rootClassName}__content`;

const ContentRenderer: React.FC<Props> = (props) => {
  const { userToken, userDetails, selectedOrgGUID, userStateInOrgs } = props;

  const history = useHistory();
  const location = useLocation();
  const isSelectedOrgPrimary = selectedOrgGUID === userDetails.organization?.guid;
  const userStateinCurrentOrg = userStateInOrgs.find(
    ({ organizationGUID }) => organizationGUID === selectedOrgGUID,
  )?.userState;
  const isUserDeactivatedInOrg = userStateinCurrentOrg === EntityState.DEACTIVATED;
  const isUserActivatedInOrg = userStateinCurrentOrg === EntityState.ACTIVATED;

  useEffect(() => {
    if (userStateinCurrentOrg === EntityState.SUSPENDED) {
      history.push('/billing');
    }
  }, [userStateinCurrentOrg]);

  if (userToken) {
    if (props.isNewUser) {
      return <Register />;
    }

    if (props.userErrorMessage || !userDetails.guid) {
      return <InitialLoader error={props.userErrorMessage} tryAgain={props.getUserDetails} />;
    }

    const projectsFetching = props.projectListAPIStatus === APICallStatus.LOADING;
    const hasProjectsError = props.projectListAPIStatus === APICallStatus.ERROR;
    const organizationFetching = props.orgDetailsAPIStatus === APICallStatus.LOADING;
    const hasOrganizationError = props.orgDetailsAPIStatus === APICallStatus.ERROR;
    const usageFetched = props.usageAPIStatus === APICallStatus.LOADED;
    const hasUsageError = props.usageAPIStatus === APICallStatus.ERROR;

    if (hasProjectsError) {
      return (
        <InitialLoader error={props.projectListError?.message} tryAgain={props.listProjects} />
      );
    }

    if (hasOrganizationError) {
      return (
        <InitialLoader
          error={props.orgDetailsError}
          tryAgain={() => props.getOrgDetails(selectedOrgGUID)}
        />
      );
    }

    if (projectsFetching || organizationFetching) {
      return <InitialLoader />;
    }

    if (
      userDetails.roleInOrganization === UserRoles.ADMIN &&
      isSelectedOrgPrimary &&
      [EntityState.ACTIVATED, EntityState.SUSPENDED].includes(userDetails.state) &&
      (hasUsageError || !usageFetched)
    ) {
      <InitialLoader
        error={props.usageError || ''}
        tryAgain={() => props.getUsageDetails(userDetails.organization?.guid || '')}
      />;
    }
  }

  return (
    <Content className={`${pageContainerClassName} antd-override`}>
      <Routes
        accountState={userDetails.state}
        roleInOrganization={userDetails.roleInOrganization}
        projects={props.projects}
        isSelectedOrgPrimary={isSelectedOrgPrimary}
        isUserDeactivatedInOrg={isUserDeactivatedInOrg}
        isUserActivatedInOrg={isUserActivatedInOrg}
      />
    </Content>
  );
};

const mapStateToProps = ({ common, projects, organization, usage }: AppState) => ({
  userToken: common.userToken,
  userDetails: common.userDetails,
  isNewUser: common.isNewUser,
  userErrorMessage: common.errorMessage,
  projectListAPIStatus: projects.listAPIStatus,
  projectListError: projects.error,
  projects: projects.list,
  orgDetailsAPIStatus: organization.apiStatus,
  orgDetails: organization.details,
  orgDetailsError: organization.error,
  selectedOrgGUID: common.selectedOrgGUID,
  usageAPIStatus: usage.apiStatus,
  usageError: usage.error,
  userStateInOrgs: common.userDetails.userStateInOrgs,
});

const actionCreators = {
  getUserDetails,
  listProjects,
  getOrgDetails,
  getUsageDetails,
};

export default connect(mapStateToProps, actionCreators)(ContentRenderer);
