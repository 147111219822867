import React, { lazy } from 'react';

import { Project } from 'Models/projects/types';

const AuthHeader = lazy(() => import('./AuthHeader'));

interface Props {
  userToken: string;
  userFilteredProjects: Project[];
}

const HeaderRenderer: React.FC<Props> = (props) => {
  if (props.userToken) {
    return <AuthHeader userFilteredProjects={props.userFilteredProjects} />;
  }

  return null;
};

export default HeaderRenderer;
