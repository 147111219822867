import {
  APICallStatus,
  CommonAction,
  RIOError,
  UpdateProjectsAndSelectedProjectPayload,
} from 'Models/common/types';
import {
  Project,
  ProjectRejectedPayload,
  ProjectsAction,
  ProjectsActionPayload,
  ProjectsState,
} from 'Models/projects/types';
import { Device } from 'Root/models/devices/types';
import { Secret } from 'Root/models/secrets/types';

import CommonActionTypes from '../common/actionTypes';

import ActionTypes from './actionTypes';

export const initialState: ProjectsState = {
  list: [],
  listAPIStatus: APICallStatus.INITIAL,
  apiStatus: APICallStatus.INITIAL,
  data: null,
  error: null,
  deviceList: [],
  deviceListAPIStatus: APICallStatus.INITIAL,
  secretsList: null,
  secretsListAPICallStatus: APICallStatus.INITIAL,
};

const projectsReducer = (
  state: ProjectsState = initialState,
  action:
    | ProjectsAction<ProjectsActionPayload>
    | CommonAction<UpdateProjectsAndSelectedProjectPayload>,
): ProjectsState => {
  switch (action.type) {
    case ActionTypes.PROJECTS_GET_PENDING:
      return {
        ...state,
        listAPIStatus: APICallStatus.LOADING,
      };

    case ActionTypes.SET_PROJECTS_API_STATUS:
      return {
        ...state,
        apiStatus: action.payload as APICallStatus,
      };

    case ActionTypes.PROJECTS_GET_FULFILLED: {
      const payload = action.payload as Project[];

      return {
        ...state,
        list: payload,
        listAPIStatus: APICallStatus.LOADED,
      };
    }

    case ActionTypes.PROJECTS_GET_REJECTED:
      return {
        ...state,
        list: [],
        listAPIStatus: APICallStatus.ERROR,
      };

    case ActionTypes.DELETE_PROJECT_FULFILLED:
      return {
        ...state,
        apiStatus: APICallStatus.LOADED,
        list: state.list.filter((item) => item.name !== (action.payload as string)),
      };

    case ActionTypes.CREATE_PROJECT_FULFILLED:
      return {
        ...state,
        list: state.data
          ? [...state.list, state.data].sort((former, latter) =>
              former.name.toLowerCase().localeCompare(latter.name.toLowerCase()),
            )
          : [...state.list],
        apiStatus: APICallStatus.INITIAL,
      };

    case ActionTypes.CREATE_PROJECT_REJECTED: {
      const payload = action.payload as RIOError;
      return {
        ...state,
        apiStatus: APICallStatus.ERROR,
        error: payload,
      };
    }

    case ActionTypes.EDIT_PROJECT_FULFILLED: {
      const projects = state.list.filter((project: Project) => project.guid !== action.payload);
      return {
        ...state,
        list: state.data
          ? [...projects, state.data].sort((former, latter) =>
              former.name.toLowerCase().localeCompare(latter.name.toLowerCase()),
            )
          : [...state.list],
      };
    }

    case ActionTypes.EDIT_PROJECT_REJECTED: {
      const payload = action.payload as RIOError;
      return {
        ...state,
        error: payload,
      };
    }
    case ActionTypes.PROJECT_DETAILS_GET_PENDING:
      return {
        ...state,
        apiStatus: APICallStatus.LOADING,
        error: null,
      };

    case ActionTypes.PROJECT_DETAILS_GET_FULFILLED:
      return {
        ...state,
        apiStatus: APICallStatus.LOADED,
        error: null,
        data: action.payload as Project,
      };

    case ActionTypes.PROJECT_DETAILS_GET_REJECTED: {
      const payload = action.payload as ProjectRejectedPayload;

      return {
        ...state,
        apiStatus: APICallStatus.ERROR,
        error: payload.error,
      };
    }

    case ActionTypes.CLEAR_DETAILS: {
      return {
        ...state,
        data: null,
      };
    }

    case ActionTypes.RESET_API_STATUS: {
      return {
        ...state,
        apiStatus: APICallStatus.INITIAL,
        error: null,
      };
    }

    case ActionTypes.SET_DOCKER_CACHE_DEVICES_LIST:
      return {
        ...state,
        deviceList: action.payload as Device[],
      };

    case ActionTypes.SET_DOCKER_CACHE_DEVICES_LIST_API_STATUS:
      return {
        ...state,
        deviceListAPIStatus: action.payload as APICallStatus,
      };

    case CommonActionTypes.UPDATE_PROJECTS_AND_SELECTED_PROJECT: {
      return {
        ...state,
        list: (action.payload as UpdateProjectsAndSelectedProjectPayload).projects,
        listAPIStatus: APICallStatus.LOADED,
      };
    }

    case ActionTypes.SET_DOCKER_CACHE_SECRETS_LIST: {
      return {
        ...state,
        secretsList: action.payload as Secret[],
      };
    }
    case ActionTypes.SET_DOCKER_CACHE_SECRETS_LIST_API_STATUS: {
      return {
        ...state,
        secretsListAPICallStatus: action.payload as APICallStatus,
      };
    }

    default:
      return state;
  }
};

export default projectsReducer;
